import React from "react"

import { rhythm, scale } from "../utils/typography"
import Header from "./template/Header"
import styled, { keyframes } from "styled-components"

interface Props {
  location: Location
  title: string
  children?: any
  pageColor?: string
  pageContrastColor?: string
  textColor?: string
  logoColor?: string
}

const Layout = ({ location, title, children, pageColor, pageContrastColor, textColor, logoColor }: Props) => {
  const SideText = styled.span`
    font-family: Zapfino;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: ${textColor || "#08760C"};
    font-size: 5rem;
    white-space: nowrap;
  `

  const Main = styled.main`
    display: flex;
  `

  const splitScreenPct = 50;

  const TestA = keyframes`
  from {
    transform: skew(00deg);
  }
    
  25% {
      transform: skew(5deg)
  }

  50% {
    transform: skew(0deg)
  }
    
  75% {
    transform: skew(-5deg)
  }
    
  to {
    transform: skew(0deg)
  }
  `

  const SplitScreen = styled.div`
    background: linear-gradient(90deg, ${pageColor} ${splitScreenPct}%, ${pageContrastColor || "#000"} ${splitScreenPct}%);
  `

  return (
    <SplitScreen style={{
      width: '100%',
      minHeight: '100vh'
    }}>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          width: 1200,
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <Header textColor={textColor} logoColor={logoColor} />
        <Main >
          <SideText>Lydknust 2021 - Elektronisk musik og lydkunst i 15 år</SideText>
          <div style={{ flexGrow: 1, padding: "0 4rem" }}>{children}</div>
          <SideText>Lydknust 2021 - Elektronisk musik og lydkunst i 15 år</SideText>
        </Main>
      </div>
    </SplitScreen>
  )
}

export default Layout
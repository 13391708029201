import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"

interface HeaderProps {
  textColor?: string
  logoColor?: string
}

const Header: React.FC<HeaderProps> = ({ textColor = "#08760C", logoColor= "black"}) => {
  const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
  `

  const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 50%;
  `

  const Logo = styled.div`
    width: 315px;
    height: 145px;
    background-image: url('/images/logo-${logoColor}.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: transform 800ms ease-in-out;
    
    &:hover {
      transform: skewX(180deg);
    }
  `

  const NavLink = styled.a`
    width: 25%;
    color: ${textColor};
    text-decoration: none !important;
    align-self: start;
    cursor: pointer;
    -webkit-box-shadow: none!important;
  `

  return (
    <Header>
      <NavLink href="https://twitch.tv/lydknust_" style={{ marginLeft: 124 }}>Livestream</NavLink>
      <LogoContainer>
        <Link to="/" style={{ boxShadow: 'none' }} >
          <Logo />
        </Link>
      </LogoContainer>
      <NavLink href="https://soundcloud.com/lydknust" style={{ marginRight: 124, textAlign: 'right' }} >Vores Soundcloud</NavLink>
    </Header>
  )
}

export default Header